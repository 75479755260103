#user-terms-container {
  padding: 2rem;
  width: 80%;
  margin: auto;
}

#user-terms-container h1 {
    margin-top: 7rem;
  text-align: center;
}

#user-terms-container p {
  font-weight: 200;
}

#user-terms-container a {
  color: rgb(80, 135, 254);
}
