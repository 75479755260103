/* GENERAL */

.footer {
  height: 252px;
  color: white;
  background-color: var(--blue) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.inline {
  display: inline;
}

.box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 15rem;
  height: 170px;
}

.footer a {
  text-decoration: underline;
  color: white;
}

/* BOX 1 */
img {
  height: 5rem;
}

.navlist {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.inner-nav {
  display: flex;
  flex-direction: column;
}

.below-home {
  padding-top: 1em;
}

/* BOX 2 */
.second {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  height: 170px;
}

.boxsecond-socials {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 15rem;
  height: 170px;
}

.social-icon {
  height: 2.5rem;
}
.hippa-icon {
  height: 7.5rem;
}

.BBB-icon {
  height: 5.1rem;
  padding-bottom: 1.5rem;
}

.inline {
  display: inline;
}

/* BOX 3 */
.third {
  flex-direction: column !important;
  justify-content: center !important;
  font-weight: 300;
  font-size: small;
}

/* MOBILE RESPONSIVE */
@media only screen and (max-width: 740px) {
  .footer {
    height: 590px;
    color: white;
    background-color: var(--blue) !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .box {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 15rem;
    height: 100px;
  }

  /* BOX 1 */
  .navlist {
    display: flex;
    padding-left: 20px;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .below-home {
    padding-top: 0rem;
  }
  .inner-nav {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .inner-two {
    padding-left: 20px;
  }

  /* BOX 2 */
  .second {
    margin-bottom: 60px;
  }
  /* BOX 3 */
  .third {
    flex-direction: column !important;
    justify-content: center !important;
    text-align: center;
    font-weight: 300;
    font-size: small;
    padding-bottom: 80px;
  }
}
