* {
  box-sizing: border-box;
}

html{
  height: 100%;
}

body {
  font-family: var(--poppins);
  margin: auto;
  height: inherit;
}

.main-content {
  /* background-color: rgb(99, 81, 58); */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}