h1 {
  font-family: var(--lora);
  font-weight: 400;
  font-size: 2.4em;
}

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

/*  BANNER  */
.video-back {
  display: none;
}

.banner-text h1 {
  font-size: 2.8rem !important;
}

.color-overlay {
  display: none;
}

.banner-background {
  max-width: 1585px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  background-image: url("../images/homepage/temp-banner.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 46rem;
}
.mobile-sub-header {
  display: none;
}
.banner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 18rem;
  width: 28rem;
  margin-left: 9rem;
  color: #ffffff;
  margin-top: 8rem;
}

.sub-heading {
  width: 36rem;
  margin-top: -15px;
  /* background-color: rgb(167, 141, 107); */
}

.banner-icons {
  margin-top: 15.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* background-color: antiquewhite; */
  width: 52rem;
}

.banner-icons-row-2 {
    margin-top: 1rem;
    margin-left: -0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* background-color: antiquewhite; */
    width: 52rem;
}
.banner-icon {
  height: 4.5rem;
}
.banner-icon2 {
    margin-top: 0.2rem;
    
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* background-color: antiquewhite; */
}
.banner-icon-statlink{
    margin-top: 0.2rem;
    display: flex;
    flex-direction: row;
    margin-left: -7rem;
    justify-content: space-evenly;
    /* background-color: antiquewhite; */
}

.bannerbutton-container {
  margin-top: 2rem;
}
.banner-button a {
  text-decoration: none;
  color: white;
}

/*VIDEO*/
.video video-container{
    margin: 6rem; 
}

#video-container {
  margin-top: 2rem;
}
.video-h2 {
    position: absolute;
    width: 704px;
    height: 59px;
    left: 620px;
    top: 940px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    color: #000000;
}
.video-iframe youtubeVideo {
    position: absolute;
    width: 1125px;
    height: 633px;
    left: 398px;
    top: 1046px;
}

/* THREE CONTENT BLOCK */
.main-content {
  max-width: 1523px;
}

.pad-top {
  padding-top: 20px;
}

.pad-top-more {
  margin-top: 2.5em;
}

.block {
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: center;
}
.three-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* background-color:bisque; */
}

.content-block {
  width: 22rem;
  height: 25rem;
  /* background-color: #c09b9b; */
}
.contentblockheader-container {
  text-align: center;
  align-items: center;
}

.block-image {
  width: 100%;
  display: flex;
  justify-content: center;
}

.threecontent-image {
  height: 4rem;
  width: auto;
}

.customer-button {
  margin-top: 0.3rem;
}
/* TESTIMONIALS */

.testimonial-image {
  width: auto;
  height: 6rem;
}

.testimonial-block {
  border-radius: 8px;
  text-align: center;
  padding: 14px 14px 14px 14px;
  box-shadow: 5px 5px 10px 2px #dfdfdf;
  width: 20rem;
  height: 17rem;
  /* background-color: burlywood; */
}

.testimonial-block h3 {
  color: #555555;
  font-weight: 500;
}

/* VISION */

.vision-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 6.5rem;
  max-width: 1522px;
}

.vision-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  width: 80%;
}

.vision-image img {
  border-radius: 8px;
  width: 32rem;
  height: auto;
}

.vision-title {
  width: 100%;
  /* text-align: center; */
  color: var(--blue);
}
.vision-title h5 {
  font-size: 18px;
  font-weight: 500;
}

.vision-contents h2 {
  font-family: var(--lora);
  font-size: 26px;
}

.vision-text-container {
  width: 33rem;
}

/* VISUALIZATION */

.visualization-container {
  display: flex;
  padding-top: 70px;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.visualization-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50rem;
  justify-content: space-between;
}

.visualization-title {
  width: 30rem;
  padding-bottom: 20px;
}
.visualization-content h2 {
  font-family: var(--lora);
  font-size: 26px;
}

.visualization-content p {
  padding-top: 30px;
}

.visualization-content img {
  padding-left: 40px;
  height: auto;
  width: 50rem;
}

.small-visualization {
  display: none;
}

/* SEND FORM */
.sendform-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  height: 25rem;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 180px;
  max-width: 1496px;
}

.sendform-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color:rgb(206, 206, 206); */
  width: 60%;
  height: 20rem;
}

.sendform-text h2 {
  font-family: var(--lora);
  font-size: 26px;
}
.sendform-text p {
  font-size: 18px;
}
.text-p {
  margin-top: 2rem;
  width: 70%;
}
.form-image {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-image img {
  width: auto;
  height: 8rem;
}

.sendform-form {
  display: flex;
  flex-direction: column;
}
.sendform-form form {
  display: flex;
  flex-direction: column;
}

.success-notification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.ortext-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: -15px;
  margin-bottom: -29px;
}

.ortext-container h4 {
  color: grey;
  font-weight: 500;
}
/* IN THE NEWS */

#news-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--blue); */
  margin-bottom: 20px;
  height: 18rem;
}
#news-container h2 {
  font-weight: 500;
  margin-top: 0;
  color: black;
}

.news-content {
  padding-top: 0px !important;
}

.news-block {
  text-align: center;
  padding: 14px 14px 14px 14px;
  box-shadow: none;
  width: 20rem;
  height: 10rem;
  /* background-color: burlywood; */
}

.news-one {
  background-image: url("../images/homepage/temp-News1.png");
}

.news-two {
  background-image: url("../images/homepage/temp-News2.png");
}

.news-three {
  background-image: url("../images/homepage/temp-News3.png");
}

.news-block h3,
.news-block p {
  color: white;
}

.news-block p a {
  font-weight: 300;
  color: white;
}
/* BLOG */

.article-text-container h4 {
  color: grey;
  font-weight: 400;
}

.article-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.article-container {
  padding-top: 0px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  #youtube-video {
    width: 300px;
    height: 175px;
  }
} 
