/* -------- CSS VARIABLES -------- */

:root {
  --poppins: "Poppins", Arial, Helvetica, sans-serif;
  --lora: "Lora", serif;
  --blue: #002145;
  --orange: rgb(245, 128, 2);
}

a {
  text-decoration: none;
}