@media only screen and (max-width: 1191px){
.blog-header {
    display: flex;
    flex-direction: row;
    width: 50rem;
    font-size: 14px;
}
}

@media only screen and (max-width: 839px){
.blog-header {
    display: flex;
    flex-direction: row;
    width: 80%;
    font-size: 14px;
}
.blogpost-description {
width: 100%;
}
}

@media only screen and (max-width: 391px){
    .blogimage-container {
width: 100%;
height: 15rem;
overflow: hidden;
margin-bottom: -5px;
}

}
/* BLOG POST */
@media only screen and (max-width: 1206px){
.bloglist-container {
    width: 55rem;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    grid-auto-rows: minmax(50px, auto);
}
.blogimage-container {
    width: 18rem;
    height: 13rem;
    overflow: hidden;
    margin-bottom: -5px;
}
}

@media only screen and (max-width: 972px){
    .bloglist-container {
    width: 40rem;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    grid-auto-rows: minmax(50px, auto);
}
}

@media only screen and (max-width: 697px){
    .bloglist-container {
    width: 20rem;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
    grid-auto-rows: minmax(50px, auto);
}
} 

@media only screen and (max-width: 391px){
    .bloglist-container {
    width: 80%;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
    grid-auto-rows: minmax(50px, auto);
}
.blogimage-container {
    width: 100%;
    height: 13rem;
    overflow: hidden;
    margin-bottom: -5px;
}
}

/* BLOG ARTICLE */

@media only screen and (max-width: 1230px){
.blogarticlepage-container {
margin-top: 8rem;
display: flex;
flex-direction: column;
width: 50rem;
}
}

@media only screen and (max-width: 947px){
.blogarticlepage-container {
margin-top: 8rem;
display: flex;
flex-direction: column;
width: 80%;
}
}

@media only screen and (max-width: 727px){
.article-content{
    text-indent: 1cm;
    margin-bottom: 5rem;
}
}

@media only screen and (max-width: 429px){
.blogarticlepage-container {
margin-top: 5.7rem;
display: flex;
flex-direction: column;
width: 80%;
}
}