/* TIME SAVED */

@media only screen and (max-width: 676px){
.timesaved-content {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px;
    width: 80%;
    height: 10rem;
    box-shadow: 5px 5px 10px 2px #dfdfdf;
}

.timesaved-text {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
}
}

@media only screen and (max-width: 472px){
.timesaved-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 80%;
    height: 10rem;
    box-shadow: 5px 5px 10px 2px #dfdfdf;
}
.timesaved-text {
    margin-top: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}
.timesaved-text img {
    height: auto;
    width: 4rem;
    margin-right: 0px;
}
.timesaved-clients{
    width: 70%;
    margin-right: 30px; 

}
.timesaved-hours {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 50%;
}
}

@media only screen and (max-width: 335px){
.timesaved-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 19px;
    width: 90%;
    height: 10rem;
    box-shadow: 5px 5px 10px 2px #dfdfdf;
}
.timesaved-clients{
    width: 100%;
    margin-right: 30px; 

}
}

/* USE CASES */

@media only screen and (max-width: 1200px){
   .usecases-container {
    display: none;
}
}

/* TABLE */
@media only screen and (max-width: 1080px){
    .table-content {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 49rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: var(--lora);
}
}

@media only screen and (max-width: 754px){
.horizontal-slider::-webkit-scrollbar {
    display: none;
}
.horizontal-slider {
-ms-overflow-style: none;
}
.horizontal-slider {
        margin-top: 10px;
    margin-bottom: 50px;
display: block;
width: 100%;
overflow-x: scroll;
box-sizing: border-box;
}
    .table-content {
    width: 100%;
    display: inline-block;
white-space: nowrap;   
    font-family: var(--lora);
    overflow-x: scroll;
}
.price-column {
    width: 247px;
    display: inline-block;
  background-color: white;
    box-shadow: 5px 5px 10px 2px #eeeeee;
  padding: 2rem;
    flex-grow: none;
  flex-basis: none;
  border-radius: 8px;
  margin-right: 15px;
}

.feature-container {
display: flex;
flex-direction: column;
margin-left: 15px;
align-items: left;
width: 90%;
}

.feature {
    width: 165px;
    white-space:normal;   
}
}