.pricing-container {
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin-top: 9.5rem;
}

/* TIME SAVED */
.timesaved-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.timesaved-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px;
    width: 35rem;
    height: 10rem;
    box-shadow: 5px 5px 10px 2px #dfdfdf;
}

.timesaved-clients{
    width: 50%;
    margin-right: 30px; 

}

.timesaved-clients h2 {
font-family: var(--lora);
font-weight: 500;

}

.timesaved-hours {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#hours-time {
    font-size: 22px;
    font-weight: 500;
}

.timesaved-hours h2 {
    font-family: var(--lora);
    font-size: 40px;
    font-weight: 300;
}

.timesaved-text {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 33rem;
}

.timesaved-text img {
    height: auto;
    width: 4rem;
    margin-right: 30px;
}

/* USE CASES */

.usecases-container {
    width: 70rem;
}

.usecases-header h2 {
    font-family: var(--lora);
    font-weight: 400;
    font-size: 26px;

}

.usecases-allcontent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.usecases-imagecontainer {
    width: 22rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.usecases-image {
  border-radius: 8px;
display: block;
height: auto;
    width: 22rem;

}

.image-overlay {
  border-radius: 8px;
    position: absolute;
    padding: 30px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:#002145b9;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
}

.image-overlay h3 {
    font-family: var(--lora);
    font-size: 28px;
    font-weight: 400;
}

.image-overlay > *{
transform: translateY(20px);
transition: transform 0.25s;
}

.image-overlay:hover {
    opacity: 1;
}

.image-overlay:hover > *{
    transform: translateY(0);
}

/* PRICING */


.table-content {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 63rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: var(--lora);
}

.price-column {
  background-color: white;
    box-shadow: 5px 5px 10px 2px #eeeeee;
  padding: 2rem;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 275px;
  border-radius: 8px;
}

.feature-container {
display: flex;
flex-direction: column;
margin-left: 15px;
align-items: left;
width: 90%;
}

.feature-container p {
    font-weight: 500;
}

.price-column:first-child {
  border-top-right-radius: 0; 
  border-bottom-right-radius: 0; 
}

.price-column:last-child {
  border-top-left-radius: 0; 
  border-bottom-left-radius: 0; 
}

.price-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}

.price {
  font-size: 3.5rem;
  display: flex;
}

.dollar-sign {
  font-size: 1.5rem;
  margin-top: .5rem;
  margin-right: .25rem;
}

.per-month {
  font-size: .75rem;
  align-self: flex-end;
  margin-bottom: 1.1rem;
  text-transform: uppercase;
}

.alternate-payment{
color: grey;
font-weight: 400;
font-family: var(--lora);
}

.plan-name {
  text-transform: uppercase;
  font-size: .9rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, .2);
  margin-top: 2rem;
  margin-bottom: .5rem;
}

.feature {
  display: flex;
  align-items: center;
  margin: .2rem;
}

.feature img {
  height: 1.1em;
  width: 1.1em;
  margin-right: .5rem;
}

.feature.inactive {
  color: #999;
  text-decoration-line: line-through;
}

.cta {
  border: solid;
  border-radius: 23px;
  background-color: var(--blue);
  color: white;
  width: 100%;
  padding: .75rem 1.5rem;
  cursor: pointer;
  font-family: var(--heading-font-family);
  font-weight: bold;
  margin-top: 1rem;
  transition: 100ms;
  transform: scale(1);
}

.cta:hover, .cta:focus {
  transform: scale(1.1);
}