body {
    margin: 0;
    padding: 0;

}

@font-face {
    font-family: Inter-Regular;
    src: url("./components/assets/fontfamily/Inter-Regular.ttf");
}

@font-face {
    font-family: Lato-Bold;
    src: url("./components/assets/fontfamily/Lato-Bold.ttf");
}

@font-face {
    font-family: Lato-Italic;
    src: url("./components/assets/fontfamily/Lato-Italic.ttf");
}

@font-face {
    font-family: Lora-Bold;
    src: url("./components/assets/fontfamily/Lora-Bold.ttf");
}

@font-face {
    font-family: Poppins-Bold;
    src: url("./components/assets/fontfamily/Poppins-Bold.ttf");
}

@font-face {
    font-family: Poppins-Medium;
    src: url("./components/assets/fontfamily/Poppins-Medium.ttf");
}

@font-face {
    font-family: Poppins-Regular;
    src: url("./components/assets/fontfamily/Poppins-Regular.ttf");
}

@font-face {
    font-family: PTSans-Bold;
    src: url("./components/assets/fontfamily/PTSans-Bold.ttf");
}

@font-face {
    font-family: PTSans-Regular;
    src: url("./components/assets/fontfamily/PTSans-Regular.ttf");
}

@font-face {
    font-family: Poppins-Italic;
    src: url("./components/assets/fontfamily/Poppins-Italic.ttf");
}

@font-face {
    font-family: 'Sansation';
    src: url('./components/assets/fontfamily/Sansation-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sansation';
    src: url('./components/assets/fontfamily/Sansation-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sansation';
    src: url('./components/assets/fontfamily/Sansation-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Sansation';
    src: url('./components/assets/fontfamily/Sansation-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Sansation';
    src: url('./components/assets/fontfamily/Sansation-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Sansation';
    src: url('./components/assets/fontfamily/Sansation-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}


@font-face {
    font-family: 'Avenir';
    src: url('./components/assets/fontfamily/Avenir-Heavy.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Roman';
    src: url('./components/assets/fontfamily/Avenir-Roman.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'Avenir-Medium';
    src: url('./components/assets/fontfamily/Avenir-Medium.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Heavy';
    src: url('./components/assets/fontfamily/Avenir-Heavy.ttf') format('truetype');
    font-style: normal;
}