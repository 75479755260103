.team-container {
    margin-top: 6.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* TEAM BANNER */

.teambanner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--blue);
    padding: 20px 40px;
    color: white;
    border-radius: 8px;
    width: 48rem;
    margin-top: 2.5rem;
    margin-bottom: 40px;
}

/* TEAM LIST */
.teaminformation-container{
    text-align: center;
}
.teamlist-container {
    width: 70rem;
    margin-top: 4rem;
    margin-bottom: 6rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    grid-auto-rows: minmax(50px, auto);
}

/* TEAM MEMBER */
.teammember-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.teammemberimage-container {
width: 15rem;
height: 15rem;
overflow: hidden;
}

.teammemberimage-container img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.teammember-name {
    font-weight: 500;
    margin-bottom: -15px;
}

.teammember-position {
font-weight: 500;
color:rgb(126, 126, 126);
    margin-bottom: -10px;
}

.teammember-description {
width: 80%;
}
