.logo {
  left: 1%;
  top: 12.0%;
  height: 4.5em;
  margin-right: 1em;
}

.nav-container {
  color: white;
  height: 5.9em;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--blue);
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1000;
}

.logoname-container {
  display: flex;
  align-items: center;
  margin-left: 2em;
  margin-right: 10%;
}

.nav-buttons {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.company-name-intials {
  color: white;
  font-size: 1.75em;
  font-family: "Lora", serif;
}

.menu {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 6px;
}

/*.product-button {
  padding: 1px 0px;
}*/

.menunav-button:hover {
  border-radius: 6px;
  color: var(--blue) !important;
  background-color: white;
  transition: .5s;
}

#home-topnav {
    /* margin-right: 3rem;*/
}

.product-button {
  padding: 4px 26px;
}

#donate.donate-button {
    padding: 4px 10px;
    margin-right: 25px;
    cursor: pointer;
    color: white;
}

/* DROPDOWN MENU */
.menunav-button {
  /* padding: 4px 18px; */
  cursor: pointer;
}

.dropmenu-container {
 
  position: relative;
  display: inline-block;
}

.aboutus-container {
    margin-right: 2.5em;
    position: relative;
    display: inline-block;
    padding: 4px 28px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  padding-bottom: 10px;
  box-shadow: 2px 2px 20px .4px #00214548;
  z-index: 1;
}

.dropdown-menu a {
  text-decoration: none;
  margin-bottom: 4px;
  color: var(--blue);
}

.dropdown-menu a:hover {
  text-decoration: underline !important;
}

.dropmenu-container:hover .dropdown-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: fit-content;
  padding: 18px 9px;
  border-radius: 8px;
  transition: .9s;
}

.company-name {
  left: 4em;
  top: 3%;
  font-size: 1.75em;
  font-family: var(--lora);
  color: white;
}

.initials-container {
  display: flex;
  width: 7em;
  margin-right: 2.5em;
  margin-left: auto;
}

/* HAMBURGER MENU */

/* General Styles */
.menu-wrap {
  position: fixed;
  top: .6%;
  right: 4%;
  z-index: 1;
}

.menu-wrap .toggler {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
}

.menu-wrap .hamburger {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 70px;
  height: 85px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hamburger Line */

.menu-wrap .hamburger>div {
  position: relative;
  flex: none;
  width: 100%;
  height: 3px;
  background: #fff;
  display: none;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

/* Hamburger Lines - Top & Bottom */
.menu-wrap .hamburger>div::before,
.menu-wrap .hamburger>div::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: -12px;
  width: 100%;
  height: 3px;
  background: inherit;
}

/* Moves Line Down */
.menu-wrap .hamburger>div::after {
  top: 12px;
}

/* Toggler Animation */
.menu-wrap .toggler:checked+.hamburger>div {
  transform: rotate(135deg);
}

/* Turns Lines Into X */
.menu-wrap .toggler:checked+.hamburger>div:before,
.menu-wrap .toggler:checked+.hamburger>div:after {
  top: 0;
  transform: rotate(90deg);
}

/* Rotate On Hover When Checked */
.menu-wrap .toggler:checked:hover+.hamburger>div {
  transform: rotate(225deg);
}

/* Show Menu */
.menu-wrap .toggler:checked~.menu {
  visibility: visible;
}

.menu-wrap .toggler:checked~.menu>div {
  transform: scale(1);
  transition-duration: 0.75s;
}

.menu-wrap .toggler:checked~.menu>div>div {
  opacity: 1;
  transition: opacity 0.4s ease 0.4s;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-wrap .menu>div {
  background: rgba(24, 39, 51, 0.85);
  border-radius: 50%;
  width: 200vw;
  height: 200vw;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.4s ease;
}

.menu-wrap .menu>div>div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu>div>div>ul {
  margin-right: 38px;
}

.menu-wrap .menu>div>div>ul>li {
  list-style: none;
  color: #fff;
  font-size: 1.5rem;
  padding: 1rem;
}

.menu-wrap .menu>div>div>ul>li>a {
  color: inherit;
  text-decoration: none;
  transition: color 0.4s ease;
}

/* MOBILE RESPONSIVE */
@media only screen and (max-width: 1130px) {
  .company-name {
    font-size: 1.15em;
  }
  .company-name-trademark {
    font-size: medium;
  }
}

/* MOBILE RESPONSIVE */
@media only screen and (max-width: 1000px) {
  .menu-wrap .hamburger>div {
    position: relative;
    flex: none;
    width: 100%;
    height: 3px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
  }

  .menu {
    display: none;
  }

  .initials-container {
    display: none;
  }

  .company-name {
    font-size: 1.25em;
  }

  .company-name-trademark {
    font-size: medium;
  }
}

/* MOBILE RESPONSIVE */
@media only screen and (max-width: 420px) {
  .company-name {
    font-size: 1em;
  }

  .company-name-trademark {
    font-size: medium;
    padding-right: 45%;
  }
}