@media only screen and (max-width: 844px){
    .teambanner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--blue);
    padding: 20px 40px;
    color: white;
    border-radius: 8px;
    width: 38rem;
    margin-top: 2.5rem;
}
}

@media only screen and (max-width: 691px){
    .teambanner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--blue);
    padding: 20px 40px;
    color: white;
    border-radius: 8px;
    width: 80%;
    margin-top: 2.5rem;
}
}

@media only screen and (max-width: 525px){
.teambanner-text{
    display: none;
}
    .teambanner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--blue);
    padding: 5px 20px;
    color: white;
    border-radius: 8px;
    width: 90%;
    margin-top: 2.5rem;
}

.teambannertitle-container{
    width: 15rem;
}
}

/* TEAM MEMBER */
@media only screen and (max-width: 1206px){
    .teamlist-container {
    width: 55rem;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    grid-auto-rows: minmax(50px, auto);
}
.teammember-description {
width: 100%;
}
}

@media only screen and (max-width: 972px){
    .teamlist-container {
    width: 40rem;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    grid-auto-rows: minmax(50px, auto);
}
}

@media only screen and (max-width: 697px){
    .teamlist-container {
    width: 20rem;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
    grid-auto-rows: minmax(50px, auto);
}
} 

@media only screen and (max-width: 391px){
    .teamlist-container {
    width: 100%;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
    grid-auto-rows: minmax(50px, auto);
}
.teammember-description{
    width: 80%;
}
}