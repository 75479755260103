/* MOBILE RESPONSIVE */

/* Banner */
@media only screen and (max-width: 540px) {
  .video-back {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 40.4rem;
    width: 100%;
  }
  .color-overlay {
    z-index: 1;
    opacity: 0.6;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--blue);
    width: 100%;
    height: 100%;
  }
  .banner-background {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ffffff;
    background-image: none;
    background-image: url("../images/homepage/temp-banner-med2.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100%;
    height: 46rem;
  }
  .mobile-sub-header {
    display: flex;
  }

  .banner-text {
    display: flex;
    z-index: 999;
    /* background-color: rgb(114, 101, 83); */
    flex-direction: column;
    justify-content: center;
    height: 18rem;
    width: 80%;
    color: #ffffff;
    margin-left: 0;
    margin-top: 8rem;
  }
  .banner-icon {
    display: none;
  }

  .banner-icon2 {
    display: none;
  }
  .sub-heading {
    width: auto;
    display: none;
    /* background-color: rgb(167, 141, 107); */
  }
}


    /* tablet responsive for banner */
    @media only screen and (max-width: 1016px) and (min-width: 540px) {
        .banner-icon {
            display: none;
        }

        .banner-icon2 {
            display: none;
        }

        .banner-background {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #ffffff;
            background-image: none;
            background-image: url("../images/homepage/temp-banner-med2.svg");
            background-repeat: no-repeat;
            background-position: 50% 50%;
            width: 100%;
            height: 46rem;
        }

        .banner-text {
            display: flex;
            /* background-color: rgb(114, 101, 83); */
            flex-direction: column;
            justify-content: center;
            height: 18rem;
            width: 60%;
            color: #ffffff;
            margin-left: 0;
            margin-top: 8rem;
        }

        .banner-icon {
            display: none;
        }

        .banner-icon2 {
            display: none;
        }

        .sub-heading {
            width: auto;
            /* background-color: rgb(167, 141, 107); */
        }
    }

    /* three content parts */
    @media only screen and (max-width: 1380px) {
        .block {
            display: flex;
            flex-direction: column;
            width: 90%;
            align-items: center;
        }
        .banner-icon {
            display: none;
        }

        .banner-icon2 {
            display: none;
        }
        .banner-icon-statlink {
            display: none;
        }
    }

    @media only screen and (max-width: 1250px) {
        .customer-button {
            margin-top: -10px;
        }

        .block {
            /* background-color: coral; */
            display: flex;
            flex-direction: column;
            width: 85%;
            align-items: center;
        }

        .content-block {
            height: 24rem;
            width: 100%;
            /* background-color: burlywood; */
        }

        .padding-tablet {
            padding-left: 40px;
        }

        .hide-tablet {
            display: none;
        }
    }

    @media only screen and (max-width: 810px) {
        .customer-button {
            margin-top: 2rem;
        }
    }

    @media only screen and (max-width: 870px) {
        .three-content {
            /* background-color: rgb(192, 93, 58); */
            margin-top: 1em;
            display: flex;
            flex-direction: column;
            width: 80%;
        }

        .padding-tablet {
            padding-left: 0px;
        }

        .pad-top {
            padding-top: 0px;
        }

        .block-text {
            font-size: 20px;
        }

        .content-block {
            height: 22rem;
            width: 100%;
            /* background-color: burlywood; */
        }

        .customer-button {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: -10px;
            width: 90%;
        }
    }

    @media only screen and (max-width: 768px) {
        .content-block {
            height: 26rem;
            width: 100%;
            /* background-color: burlywood; */
        }

        .customer-button {
            width: 90%;
            margin-top: -1rem;
        }

        .customerbutton-button {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
        }
    }

    @media only screen and (max-width: 620px) {
        .banner-text h1 {
            font-size: 3.2rem !important;
        }

        .content-block {
            height: 28.5rem;
            width: 100%;
            text-align: center;
            /* background-color: burlywood; */
        }

        .customer-button {
            width: 90%;
            margin-top: -1.5rem;
        }
    }

    @media only screen and (max-width: 500px) {
        .content-block {
            height: 27rem;
            width: 100%;
            text-align: center;
            /* background-color: burlywood; */
        }

        .three-content {
            /* background-color: rgb(192, 93, 58); */
            margin-top: 1em;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .customer-button {
            width: 95%;
            margin-top: 0rem;
        }

        .customerbutton-button {
            width: 100%;
        }
    }

    @media only screen and (max-width: 432px) {
        .content-block {
            height: 29rem;
            width: 100%;
            text-align: center;
            /* background-color: burlywood; */
        }

        .customer-button {
            width: 90%;
            margin-top: -2rem;
        }
    }

    @media only screen and (max-width: 383px) {
        .content-block {
            height: 32rem;
            width: 100%;
            text-align: center;
            /* background-color: burlywood; */
        }

        .customer-button {
            width: 90%;
            margin-top: -2rem;
        }
    }

    @media only screen and (max-width: 358px) {
        .content-block {
            height: 36rem;
            width: 100%;
            text-align: center;
            /* background-color: burlywood; */
        }

        .customer-button {
            width: 90%;
            margin-top: -2rem;
        }

        .customer-button {
            width: 96%;
            margin-top: -2.3rem;
        }
    }

    /* TESTIMONIALS */

    @media only screen and (max-width: 1103px) {
        .web-only {
            display: none;
        }

        .pad-top-more {
            margin-top: 4em;
        }

        .testimonial-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            /* background-color:bisque; */
        }

        .padded-tablet {
            margin-left: 30px;
        }
    }

    @media only screen and (max-width: 721px) {
        .web-only {
            display: none;
        }

        .tab-only {
            display: none;
        }
    }

    /* VISION */
    @media only screen and (max-width: 1300px) {
        .vision-contents {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 90%;
        }
    }

    @media only screen and (max-width: 1125px) {
        .vision-contents {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 60%;
        }

        .vision-image img {
            width: 45vw;
            height: auto;
        }

        .article-image {
            margin-top: 30px;
        }

        .vision-text-container {
            text-align: center;
            width: 60vw;
        }
    }

    @media only screen and (max-width: 750px) {
        .vision-contents {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 60%;
        }

        .vision-image img {
            width: 80vw;
            height: auto;
        }

        .vision-text-container {
            text-align: center;
            width: 80vw;
        }
    }

    /* VISUALIZATION */

    @media only screen and (max-width: 900px) {
        .visualization-content {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 40rem;
            justify-content: space-between;
        }

            .visualization-content img {
                height: auto;
                width: 40rem;
            }
    }

    @media only screen and (max-width: 680px) {
        .small-visualization {
            display: flex;
            width: 100% !important;
            margin-right: 24px;
            margin-bottom: 2rem;
        }

        .visualization-content p {
            display: none;
        }

        .large-visualization {
            display: none;
        }

        .visualization-content p {
            width: 20rem;
            padding-top: 30px;
        }

        .visualization-content {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            justify-content: space-between;
        }

        .visualization-title {
            width: 100%;
            padding-bottom: 20px;
        }
    }

    /* SEND FORM */
    @media only screen and (max-width: 1200px) {
        .sendform-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            /* background-color:rgb(206, 206, 206); */
            width: 80%;
            height: 20rem;
        }
    }

    @media only screen and (max-width: 760px) {
        .form-image {
            display: none;
        }

        .sendform-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            /* background-color:rgb(206, 206, 206); */
            width: 60%;
            height: 25rem;
        }

        .text-p {
            margin-top: 2rem;
            width: 100%;
        }

        .sendform-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: black;
            height: 35rem;
            width: 100%;
            margin-top: 35px;
            margin-bottom: 80px;
        }
    }

    @media only screen and (max-width: 550px) {
        .sendform-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            /* background-color:rgb(206, 206, 206); */
            width: 80%;
            height: 20rem;
        }
    }
