.blog-container {
    margin-top:  8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog-header {
    display: flex;
    flex-direction: row;
    width: 70rem;
    font-size: 14px;
}

.blog-header img{
    width: 2rem;
    margin-right: 20px;
}

/* BLOG LIST */
.bloglist-container {
    width: 70rem;
    margin-top: 4rem;
    margin-bottom: 6rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    grid-auto-rows: minmax(50px, auto);
}

/* BLOG POST */
.blogpost-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.blogimage-container {
width: 20rem;
height: 15rem;
overflow: hidden;
margin-bottom: -5px;
}

.blogpost-container img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    display: block;
    transition: transform .5s ease;
}

.blogpost-container img:hover {
    transform: scale(1.08);
}

.blogpost-name {
    font-weight: 500;
    margin-bottom: -18px;
}

.blogpost-container h4 {
    color:rgb(128, 128, 128);
    margin-bottom: -5px;
    font-weight: 400;
}

.blogpost-position {
font-weight: 500;
color:rgb(126, 126, 126);
margin-bottom: -10px;
}

.blogpost-description {
width: 95%;
}

/* BLOG ARTICLE */
.blogarticle-container{ 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.blogarticlepage-container {
margin-top: 8rem;
display: flex;
flex-direction: column;
width: 70rem;
}

.blogarticle-textimgcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.articleimage-container {
width: 27rem;
height: 20rem;
overflow: hidden;
margin-bottom: -5px;
}

.articleimage-container img {
width: 100%;
height: 100%;
object-fit: contain;
display: block;
}

.submissionInfo-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: center;
}

.article-title {
margin-bottom: -10px;
}
h1.article-title {
    text-align: center;
}

.article-author {
    margin-right: 10px;
}

.article-date {
    margin-left: 10px;
}

.submissionInfo-container h3 {
font-weight: 500;
color:rgb(122, 122, 122);
}

.article-content {
    text-indent: 2cm;
    margin-bottom: 5rem;
    white-space: pre-line;
}


.blogpost-author{
    margin-right: 15px;
}
.authordate-container{
    display: flex;
    flex-direction: row;
    width: 100%;
} 

/* CHECK RESPONSIVNESS OF NAME DATE SECITON */